.nav-menu {
  list-style-type: none;
  text-align: center; 
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); 

  li:last-child {
    margin-right: 0; 
  }
  
  &.nav-desktop {
    font-size: 24px;
    z-index: 1;
    display: none;
    position: fixed;
    top: 0;
    padding-left: 10px;
    margin: 0;
    @include bp-only(desktop, block);
    .work-names {
      display: none;
    }
  }
}