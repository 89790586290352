.name {
    font-size: 16px;
    width: 50%;
    line-height: 2;
    text-align: justify;
    @media (max-width: 768px) {
        width: 80%;
    }
}

.content {
    @media (max-width: 768px) {
        margin-left: 10px;
    }
}