@import 'mixin.scss';
@import 'utility.scss';
@import 'Navbar.scss';
@import 'Navbar-mobile.scss';
@import 'About.scss';
@import 'Work.scss';
@import 'Contact.scss';
@import 'Home.scss';

* {
    font-family: Perpetua, serif;
}

#root {
    max-width: 2000px;
    margin: 0 auto;
}

.content {
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    
    @media #{$breakpoint-desktop} {
        margin-left: $global-left;
        margin-right: $global-left;
    }
}

