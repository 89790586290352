$margin-titles: 15px;

.mobo-nav {
    display:none;
    @include bp-only(mobo, block);
    top: 5px;
    position: relative;
    position: fixed;
    z-index: 2;


    .font-alter {
      font-size: 30px;
      line-height: 15px;
      margin-left: 10px;
    }

    .nav-overlay{
      position: inherit;
      opacity: 0;
      visibility: hidden;
      z-index:2;
      left:0;
      top:0;
      width:100%;
      height: 100%;

      &.open {
        opacity: 1;
        background-color: rgba($color: #000000, $alpha: 0.5);
        visibility: visible;
        transition: opacity 0.2s ease-in;
      }
    }

    .nav-mobile-wrapper {
      position: fixed;
      background-color: white;
      width: 50%;
      height: 100%;
      top: 0;
      opacity: 0;
      visibility: hidden;
      z-index:2;
      overflow-y: auto;


      .nav-menu{
        position: relative;
        width:100%;
        height:100%;
        padding: 0;
        text-align: left;
        margin-top: 5px;

        .menu-titles {
          margin-bottom: calc($margin-titles * 2);    
          display: flex;      
          flex-direction: column;
          gap: 5px;
        }

        .font-alter {
          margin-left: 10px;
          font-size: 30px;
          line-height: 15px;
        }

        .work-names {
          position: relative;
          margin-top: $margin-titles;
          display: flex;
          flex-direction: column;
          gap: 5px;

          .font-alter {
            font-size: 25px;
            line-height: 20px;
            letter-spacing: -3px;
            margin-right: 20px;
          }
          &::before {
            content: ''; 
            position: absolute;
            top: 0px; 
            left: 50%;
            transform: translate(-50%, 0); 
            width: 80%;
            height: 1px; 
            border-top: 1px solid black; 
            margin-top: -$margin-titles; 
          }          
        }
      }

      &.open {
        opacity: 1;
        background-color: white;
        visibility: visible;
        transition: opacity 0.2s ease-in;
      }
  }
}