.work {
    display: flex;
    flex-direction: column;

    .work-names {
        display: none;
        flex-direction: column;
        position: fixed;
        z-index: 1;
        cursor: pointer;
        @include bp-only(desktop, flex);

        .name-item {
            display: block;
            margin-bottom: 10px;
        }
    }

    .info {
        // max-width: 1200px;
        margin-left: auto;
        margin-bottom: 50px;
        width: 60%;
        font-size: 15px;
        @media (max-width: 768px) {
            margin: 0 auto; 
            margin-bottom: 30px;
            font-size: 10px;
            width: 80%;
        }
        @media (max-width: 478px) {
            font-size: 8px;
        }
    }

    .gallery {
        width: 60%;
        // max-width: 1200px;
        margin-left: auto;
        @media (max-width: 768px) {
            margin: 0 auto;
            width: 80%;
        }

        .gallery-image {
            width: 100%;
            margin-bottom: 30px;
        }

        .fade-in {
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }
        
        .fade-in.loaded {
            opacity: 1;
        }
    }    
}