.font-alter {
    display: inline-block;
    line-height: 12px;
    overflow: hidden;
    cursor: pointer;
    a {
        margin-right: 20px; 
        letter-spacing: -3px;
    }
}

a {
    text-decoration: none; 
    color:black;
}
